import { StandardDesktopBackground } from "../../components/CubeBackgrounds/StandardDesktopBackground";
import { StandardMobileBackground } from "../../components/CubeBackgrounds/StandardMobileBackground";
import { ProjectScope } from "../../components/ProjectScope/ProjectScope";
import { Fade } from "react-awesome-reveal";
import "./CubosKids.less";

export const CubosKids = () => {
    return (
        <div id="cubos-kids">
            <div className="content">
                <img
                    className="hero-image desktop"
                    src="/images/Cubosee kids_cover page.jpg"
                    alt="Cubosee kids cover page"
                />
                <img
                    className="hero-image mobile"
                    src="/images/Cubosee Kids mobile_cover page.jpg"
                    alt="Cubosee kids cover page"
                />
            </div>

            <div className="synopsis content-full">
                <StandardDesktopBackground />
                <StandardMobileBackground />

                <div className="content-synopsis">
                    <h1>Synopsis</h1>
                    <p>
                        In its essence, Cubosee kids is a natural extension of Cubosee. It introduces
                        new characters, and a slightly different animation model, but the lessons,
                        the adventures and the thought behind the brand remain the same.
                    </p>
                    <p>
                        This series is visually more adapted to a younger, preschool audience and
                        features simpler graphics not to distract from the relevant values that are
                        explored throughout the episodes.
                    </p>
                </div>
            </div>

            <div className="content-small">
                <ProjectScope
                    format={["Animated series", "3D CGI Animation", "10 minutes"]}
                    formatIcon="/images/svg/Format video_icon.svg"
                    target={["3 - 7 years"]}
                    targetIcon="/images/svg/Target younger_icon.svg"
                    genre={["Nursery rhyme"]}
                    genreIcon="/images/svg/Genre_icon.svg"
                />
            </div>

            <h1 className="text-blue">Take a sneak peek!</h1>
            <Fade direction="up" triggerOnce>
                <div id="kids-content-image" className="content">
                    <img
                        className="hero-image sneak-peek"
                        src="/images/Cubos kids_pictures gallery.jpg"
                        alt="Cubosee kids sneak peek gallery"
                    />
                </div>
            </Fade>
        </div>
    );
};
