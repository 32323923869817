import { useEffect } from "react";
import { Layout } from "./containers/Layout/Layout";
import { Route, Switch, BrowserRouter as Router, useLocation } from "react-router-dom";
import { Landing } from "./containers/Landing/Landing";
import { CubosGeneral } from "./containers/CubosGeneral/CubosGeneral";
import { CubosKids } from "./containers/CubosKids/CubosKids";
import { CubosAdriatic } from "./containers/CubosAdriatic/CubosAdriatic";
import { CubosVR } from "./containers/CubosVR/CubosVR";
import { Contact } from "./containers/Contact/Contact";
import { Privacy } from "./containers/Privacy/Privacy";
import { Legal } from "./containers/Legal/Legal";

// Scrolling on top when changing pages!
export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        document.querySelector("#root")?.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <Layout>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/cubos" component={CubosGeneral} />
                    <Route exact path="/cubos-kids" component={CubosKids} />
                    <Route exact path="/cubos-adriatic" component={CubosAdriatic} />
                    <Route exact path="/cubos-vr" component={CubosVR} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/legal" component={Legal} />

                    <Route component={Landing} />
                </Switch>
            </Layout>
        </Router>
    );
}

export default App;
