export const StandardMobileBackground = () => {
    return (
        <>
            {/** Top left */}
            <div
                className="background-cube mobile"
                style={{
                    left: "30px",
                    top: "30px",
                }}
            ></div>

            {/** Bottom right */}
            <div className="background-cube mobile" style={{ bottom: "20px", right: "60px" }}></div>
            <div
                className="background-cube mobile small"
                style={{ bottom: "55px", right: "30px" }}
            ></div>
        </>
    );
};
