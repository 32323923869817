import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { CubosHero } from "../CubosHero/CubosHero";
import { Tagline } from "../Tagline/Tagline";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./VideoCarousel.less";

export const VideoCarousel = () => {
    const [selectedVideo, setSelectedVideo] = useState(0);
    const totalNumOfVideos = 4;

    const headerVideoEnded = (index: number) => {
        if (selectedVideo === index) {
            setSelectedVideo((selectedVideo + 1) % totalNumOfVideos);
        }
    };

    return (
        <Carousel
            className="cubos-carousel"
            selectedItem={selectedVideo}
            onChange={i => setSelectedVideo(i)}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance
        >
            <CubosHero
                videoLink="/videos/Cubos_clip_1200(720).mp4"
                onVideoEnd={headerVideoEnded}
                index={0}
                currentlyPlaying={selectedVideo}
            >
                <Tagline
                    link="/cubos"
                    title="Cubosee"
                    description={`Cow Moo, bear Bee and rabbit Doo are inseparable friends
                    who face everyday challenges together using their unique
                    strengths and characteristics.`}
                />
            </CubosHero>
            <CubosHero
                videoLink="/videos/Cubos Kids_clip_1200(720).mp4"
                onVideoEnd={headerVideoEnded}
                index={1}
                currentlyPlaying={selectedVideo}
            >
                <Tagline
                    link="/cubos-kids"
                    title="Cubosee Kids"
                    description={`In its essence, Cubosee kids is a natural extension of Cubosee.
                    It introduces new characters, and a slightly different
                    animation model for younger generation.`}
                />
            </CubosHero>
            <CubosHero
                videoLink="/videos/Cubos Adriatic_clip_1200(720).mp4"
                onVideoEnd={headerVideoEnded}
                index={2}
                currentlyPlaying={selectedVideo}
            >
                <Tagline
                    link="/cubos-adriatic"
                    title="Cubosee Adriatic"
                    description={`A mobile game introduces children to an island environment where
                    they get to solve simple tasks and at the same time learn about
                    Croatian islands’ cultural heritage.`}
                />
            </CubosHero>
            <CubosHero
                videoLink="/videos/Cubos VR_clip_1200(720).mp4"
                onVideoEnd={headerVideoEnded}
                index={3}
                currentlyPlaying={selectedVideo}
                videoClass="left"
            >
                <Tagline
                    link="/cubos-vr"
                    title="Cubosee VR"
                    description={`The three friends explore their surroundings and have fun solving simple tasks in a virtual reality environment.`}
                />
            </CubosHero>
        </Carousel>
    );
};
