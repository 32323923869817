import { Link } from "react-router-dom";
import { VideoCarousel } from "../../components/VideoCarousel/VideoCarousel";
import { CharacterCard } from "../../components/CharacterCard/CharacterCard";
import { StandardDesktopBackground } from "../../components/CubeBackgrounds/StandardDesktopBackground";
import { StandardMobileBackground } from "../../components/CubeBackgrounds/StandardMobileBackground";
import { Fade } from "react-awesome-reveal";

import "./Landing.less";

export const Landing = () => {
    return (
        <div id="landing">
            <div className="content">
                <VideoCarousel />
            </div>

            <div id="welcome-message" className="content-full">
                <StandardDesktopBackground />
                <StandardMobileBackground />

                <div className="content-synopsis">
                    <h1>Welcome!</h1>
                    <p>
                        Let us introduce you to Cubosee, a multi-format entertainment ecosystem
                        intended for kids. This brand features child-like animals that live in a
                        world where everything is square-shaped. We follow their adventures and
                        daily mishaps in this unusual universe.
                    </p>
                </div>
            </div>

            <h1 className="text-yellow">Cubosee categories</h1>
            <div id="categories" className="content-small">
                <Fade className="category-img" direction="up" triggerOnce>
                    <Link to="/cubos-kids">
                        <img src="/images/Category_cubosee kids.jpg" alt="Cubosee kids category" />
                    </Link>
                </Fade>
                <Fade className="category-img" direction="up" triggerOnce>
                    <Link to="/cubos">
                        <img src="/images/Category_cubosee.jpg" alt="Cubosee category" />
                    </Link>
                </Fade>
                <Fade className="category-img" direction="up" triggerOnce>
                    <Link to="/cubos-adriatic">
                        <img
                            src="/images/Category_cuboseeadriatic.jpg"
                            alt="Cubosee adriatic category"
                        />
                    </Link>
                </Fade>
                <Fade className="category-img" direction="up" triggerOnce>
                    <Link to="/cubos-vr">
                        <img src="/images/Category_cubosee VR.jpg" alt="Cubosee VR category" />
                    </Link>
                </Fade>
            </div>
            <h1 className="text-pink">Meet the characters</h1>
            <div className="content-small character">
                <CharacterCard
                    backgroundColor="yellow"
                    characterImage="/images/Bee model.png"
                    characterPosition="left"
                >
                    <div className="card-content">
                        <img src="/images/svg/Bee logo.svg" alt="Bear Bee name logo" />
                        <p>
                            As the smartest of the group, this bear easily solves problems and
                            challenges that come his way. He is the leader of the Cubosee gang but is
                            best described as the ultimate prankster who always tries to play tricks
                            on his friends and get them to do things his way.
                        </p>
                    </div>
                </CharacterCard>

                <CharacterCard
                    backgroundColor="pink"
                    characterImage="/images/Moo model.png"
                    characterPosition="right"
                >
                    <div className="card-content">
                        <img src="/images/svg/Moo logo.svg" alt="Cow Moo name logo" />
                        <p>
                            Being curious-spirited, this sweet and hyperactive cow is always looking
                            for an adventure. She is a good and loyal friend, although her excessive
                            hyperactivity can sometimes get a little tedious. She always goes
                            headfirst through the wall, and she can often be persuaded into being
                            mischievous.
                        </p>
                    </div>
                </CharacterCard>

                <CharacterCard
                    backgroundColor="blue"
                    characterImage="/images/Doo model.png"
                    characterPosition="left"
                >
                    <div className="card-content">
                        <img src="/images/svg/Doo logo.svg" alt="Rabbit Doo name logo" />
                        <p>
                            This silly and quiet bunny is always there for his friends but mostly
                            keeps to himself and stares blankly into the distance. But another side
                            of his emerges if he gets into a mishap or is overwhelmed by all the
                            pranks - he then becomes irritated and wants to fight everyone.
                            Fortunately, this side of his vanishes in an instant and he quickly
                            calms down and returns to the quiet goofy-looking bunny again.
                        </p>
                    </div>
                </CharacterCard>
            </div>
            <div id="landing-goal">
                <StandardDesktopBackground />
                <StandardMobileBackground />
                <div className="content-synopsis">
                    <h1>Goal</h1>
                    <p>
                        The main intention behind this project was to create content for children
                        that was both fun and educational. We have tried to integrate attractive
                        visuals together with pedagogical and academic knowledge.
                    </p>
                    <p>
                        The animated series explore relevant topics such as friendship, tolerance,
                        inclusion, empathy and sustainability, whereas the mobile game focuses on
                        cultural and geographical knowledge. To make these topics more
                        understandable to the young audience, they are explored through the
                        characters’ everyday experiences.
                    </p>
                </div>
            </div>
            <h1 className="text-blue">Our Values</h1>
            <div className="content-small">
                <Fade direction="up" fraction={0.3} triggerOnce>
                    <img
                        className="desktop"
                        src="/images/svg/Our values_desktop.svg"
                        alt="Graph of our values"
                    />
                    <img
                        className="mobile"
                        src="/images/svg/Our values_mobile.svg"
                        alt="Graph of our values"
                    />
                </Fade>
            </div>
        </div>
    );
};
