export const StandardDesktopBackground = () => {
    return (
        <>
            {/** Top left */}
            <div className="background-cube no-mobile" style={{ left: "100px", top: "50px" }} />
            <div
                className="background-cube small no-mobile"
                style={{ left: "140px", top: "85px" }}
            />

            {/** Top right */}
            <div className="background-cube no-mobile" style={{ right: "150px", top: "80px" }} />

            {/** Bottom left */}
            <div
                className="background-cube small no-mobile"
                style={{ left: "120px", bottom: "30px" }}
            />
            <div
                className="background-cube large no-mobile"
                style={{ left: "170px", bottom: "-24px" }}
            />

            {/** Bottom right */}
            <div
                className="background-cube small no-mobile"
                style={{ right: "80px", bottom: "90px" }}
            />
            <div className="background-cube no-mobile" style={{ right: "120px", bottom: "50px" }} />
        </>
    );
};
