import { Fade } from "react-awesome-reveal";
import "./ProjectScope.less";

export interface IProjectScopeProps {
    format: string[];
    formatIcon: string;
    target: string[];
    targetIcon: string;
    genre: string[];
    genreIcon: string;
}

export const ProjectScope = ({
    format,
    formatIcon,
    target,
    targetIcon,
    genre,
    genreIcon,
}: IProjectScopeProps) => {
    return (
        <div className="project-scope">
            <h1 className="text-yellow">Project Scope</h1>
            <div className="scope-content">
                <div className="scope-content-item">
                    <Fade direction="up" triggerOnce>
                        <div>
                            <img src={formatIcon} alt="Format icon" />
                            <h3>Format</h3>
                            {format.map(entry => (
                                <p key={entry}>{entry}</p>
                            ))}
                        </div>
                    </Fade>
                </div>
                <hr />
                <div className="scope-content-item">
                    <Fade direction="up" triggerOnce>
                        <div>
                            <img src={targetIcon} alt="Target audience icon" />
                            <h3>Age</h3>
                            {target.map(entry => (
                                <p key={entry}>{entry}</p>
                            ))}
                        </div>
                    </Fade>
                </div>
                <hr />
                <div className="scope-content-item">
                    <Fade direction="up" triggerOnce>
                        <div>
                            <img src={genreIcon} alt="Genre icon" />
                            <h3>Genre</h3>
                            {genre.map(entry => (
                                <p key={entry}>{entry}</p>
                            ))}
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
};
