import { StandardDesktopBackground } from "../../components/CubeBackgrounds/StandardDesktopBackground";
import { StandardMobileBackground } from "../../components/CubeBackgrounds/StandardMobileBackground";
import { ProjectScope } from "../../components/ProjectScope/ProjectScope";
import { Fade } from "react-awesome-reveal";
import "./CubosVR.less";

export const CubosVR = () => {
    return (
        <div id="cubos-vr">
            <div className="content">
                <img
                    className="hero-image desktop"
                    src="/images/Cubosee VR_cover page.jpg"
                    alt="Cubosee VR cover page"
                />
                <img
                    className="hero-image mobile"
                    src="/images/Cubosee VR mobile_cover page.jpg"
                    alt="Cubosee VR cover page"
                />
            </div>

            <div className="synopsis content-full">
                <StandardDesktopBackground />
                <StandardMobileBackground />

                <div className="content-synopsis">
                    <h1>Synopsis</h1>
                    <p>
                        Played with the use of VR goggles, this game features our familiar
                        characters in a friendly island setting where they enjoy sporty activities
                        such as playing basketball.
                    </p>
                    <p>
                        This fun and interactive virtual reality game requires the players to be
                        physically active to be able to solve simple tasks.
                    </p>
                </div>
            </div>

            <div className="content-small">
                <ProjectScope
                    format={["VR game"]}
                    formatIcon="/images/svg/Format VR_icon.svg"
                    target={["5 - 8 years"]}
                    targetIcon="/images/svg/Target older_icon.svg"
                    genre={["Education simulation"]}
                    genreIcon="/images/svg/Genre_icon.svg"
                />
            </div>

            <h1 className="text-blue">Take a sneak peek!</h1>
            <Fade direction="up" triggerOnce>
                <div id="vr-content-image" className="content">
                    <img
                        className="hero-image sneak-peek"
                        src="/images/Cubos VR_pictures gallery.jpg"
                        alt="Cubosee VR sneak peek gallery"
                    />
                </div>
            </Fade>
        </div>
    );
};
