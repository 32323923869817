import { CharacterCard } from "../../components/CharacterCard/CharacterCard";
import "./Contact.less";

export const Contact = () => {
    return (
        <div id="contact">
            <h1 className="text-yellow">Contact</h1>

            <div className="content contact-us">
                <CharacterCard
                    characterImage="/images/Contact_all.png"
                    characterPosition="left"
                    backgroundColor="yellow"
                >
                    <div className="contact-us-content">
                        <h1>Contact us</h1>
                        <hr />
                        <p>
                            <a href="mailto:info@cubos.fun">info@cubos.fun</a>
                        </p>
                        <p>Uprise d.o.o.</p>
                        <p>+385 95 4433 487</p>
                        <p>Trg Drage Iblera 10, 10 000 Zagreb</p>
                    </div>
                </CharacterCard>
            </div>
        </div>
    );
};
