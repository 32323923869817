import { ReactElement } from "react";
import { Fade } from "react-awesome-reveal";
import "./CharacterCard.less";

export interface ICharacterCardProps {
    characterImage: string;
    characterPosition: "left" | "right";
    backgroundColor: "pink" | "blue" | "yellow";
    children: ReactElement;
}

export const CharacterCard = ({
    characterImage,
    characterPosition,
    backgroundColor,
    children,
}: ICharacterCardProps) => {
    return (
        <div className="cubos-character">
            <div
                className={`character-background ${backgroundColor} character-${characterPosition}`}
            ></div>
            <div className={`card ${characterPosition}`}>
                <Fade className="character-animation" direction="up" triggerOnce>
                    <img src={characterImage} alt="" />
                </Fade>
                <div className="text">{children}</div>
            </div>
        </div>
    );
};
