import { ReactElement, useCallback, useEffect, useRef } from "react";
import "./CubosHero.less";

export interface ICubosHeroProps {
    videoLink: string;
    children: ReactElement;
    onVideoEnd: (index: number) => void;
    index: number;
    currentlyPlaying: number;
    videoClass?: string;
}

export const CubosHero = ({
    videoLink,
    onVideoEnd,
    children,
    index,
    currentlyPlaying,
    videoClass,
}: ICubosHeroProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const playVideo = useCallback(async () => {
        try {
            if (videoRef.current) {
                // Safari autoplay fix attempt.
                videoRef.current.muted = true;
                videoRef.current.pause();

                if (index === currentlyPlaying && videoRef.current.paused) {
                    await videoRef.current.play();
                } else if (!videoRef.current.paused) {
                    videoRef.current.pause();
                }
            }
        } catch (e) {}
    }, [currentlyPlaying, index]);

    useEffect(() => {
        playVideo();
    }, [playVideo]);

    useEffect(() => {
        let intervalHandle = setInterval(() => {
            if (!videoRef.current?.paused) {
                clearInterval(intervalHandle);
            }

            if (index === currentlyPlaying) {
                playVideo();
            }
        }, 100);

        setTimeout(() => {
            clearInterval(intervalHandle);
        }, 1000);
    }, []);

    return (
        <div className="cubos-hero">
            <div className="cubos-overlay" />
            <video
                className={videoClass}
                ref={videoRef}
                muted
                playsInline
                onEnded={() => onVideoEnd(index)}
            >
                <source src={videoLink} />
            </video>
            {children}
        </div>
    );
};
