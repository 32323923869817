import { useEffect } from "react";
import ReactGA from "react-ga4";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import "./CookieConsentPrompt.less";

export const CookieConsentPrompt = () => {
    const GOOGLE_ANALYTICS_ID = "G-49BKRLWP65";

    const handleAcceptCookie = () => {
        if (GOOGLE_ANALYTICS_ID && process.env.NODE_ENV === "production") {
            ReactGA.initialize(GOOGLE_ANALYTICS_ID);
        }
    };

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        } else {
            handleDeclineCookie();
        }
    }, []);

    return (
        <div id="cookie-wrapper">
            <CookieConsent
                containerClasses="cookie-banner"
                buttonWrapperClasses="cookie-buttons"
                contentClasses="cookie-content"
                buttonText="Accept"
                declineButtonText="Decline"
                declineButtonClasses="decline-btn"
                disableStyles
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
            >
                <div>
                    This website uses cookies to ensure you get the best experience on our website.
                </div>
            </CookieConsent>
        </div>
    );
};
