import { StandardDesktopBackground } from "../../components/CubeBackgrounds/StandardDesktopBackground";
import { StandardMobileBackground } from "../../components/CubeBackgrounds/StandardMobileBackground";
import { ProjectScope } from "../../components/ProjectScope/ProjectScope";
import { Fade } from "react-awesome-reveal";
import "./CubosGeneral.less";

export const CubosGeneral = () => {
    return (
        <div id="cubos-general">
            <div className="content">
                <img
                    className="hero-image desktop"
                    src="/images/Cubosee_cover page.jpg"
                    alt="Cubosee cover page"
                />
                <img
                    className="hero-image mobile"
                    src="/images/Cubosee mobile_cover page.jpg"
                    alt="Cubosee cover page"
                />
            </div>

            <div className="synopsis content-full">
                <StandardDesktopBackground />
                <StandardMobileBackground />

                <div className="content-synopsis">
                    <h1>Synopsis</h1>
                    <p>
                        The plot revolves around three main characters: Moo the cow, Bee the bear
                        and Doo the rabbit. They are inseparable friends who face everyday
                        challenges together using their unique strengths and characteristics. In the
                        process they learn important life lessons about teamwork, approaching
                        problems with optimism and believing in your own uniqueness.
                    </p>
                </div>
            </div>

            <div className="content-small">
                <ProjectScope
                    format={["Animated series", "3D CGI Animation", "2 minutes"]}
                    formatIcon="/images/svg/Format video_icon.svg"
                    target={["5 - 10 years"]}
                    targetIcon="/images/svg/Target older_icon.svg"
                    genre={["Slapstick comedy"]}
                    genreIcon="/images/svg/Genre_icon.svg"
                />
            </div>

            <h1 className="text-blue">Take a sneak peek!</h1>
            <Fade direction="up" triggerOnce>
                <div id="general-content-image" className="content">
                    <img
                        className="hero-image sneak-peek"
                        src="/images/Cubos_pictures gallery.jpg"
                        alt="Cubosee sneak peek gallery"
                    />
                </div>
            </Fade>
        </div>
    );
};
