import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./CubosHeader.less";

export const CubosHeader = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setMenuOpen(false);
    }, [location]);

    const scrollTop = () => {
        document.querySelector("#root")?.scrollTo(0, 0);
    };

    const renderLinks = () => {
        return (
            <>
                <Link className="nav-item" to="/" onClick={scrollTop}>
                    <img src="/images/svg/Home_icon.svg" alt="Home icon" />
                    <p>Home</p>
                </Link>
                <Link className="nav-item" to="/cubos" onClick={scrollTop}>
                    <img src="/images/svg/Cubos-Cubos kids_icon.svg" alt="Cubos icon" />
                    <p>Cubosee</p>
                </Link>
                <Link className="nav-item" to="/cubos-kids" onClick={scrollTop}>
                    <img src="/images/svg/Cubos-Cubos kids_icon.svg" alt="Cubos kids icon" />
                    <p>Cubosee Kids</p>
                </Link>
                <Link className="nav-item" to="/cubos-adriatic" onClick={scrollTop}>
                    <img src="/images/svg/Cubos adriatic_icon.svg" alt="Cubos adriatic icon" />
                    <p>Cubosee Adriatic</p>
                </Link>
                <Link className="nav-item" to="/cubos-vr" onClick={scrollTop}>
                    <img src="/images/svg/Cubos VR_icon.svg" alt="Cubos VR icon" />
                    <p>Cubosee VR</p>
                </Link>
                <Link className="nav-item" to="/contact" onClick={scrollTop}>
                    <img src="/images/svg/Contact_icon.svg" alt="Envelope icon" />
                    <p>Contact</p>
                </Link>
            </>
        );
    };

    return (
        <header className={menuOpen ? "with-glass" : ""}>
            <div className={`header-shadow glass ${menuOpen ? "no-shadow" : ""}`} />
            <div className="container content text-grey">
                <Link to="/" onClick={scrollTop}>
                    <img src="/images/Logo_color.png" alt="Cubos main logo" />
                </Link>
                <nav>{renderLinks()}</nav>

                <div
                    className={`menu-btn-container ${menuOpen ? "menu-open" : "menu-closed"}`}
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <img src="/images/svg/Menu_mobile.svg" alt="" />
                    <img src="/images/svg/X_icon.svg" alt="" />
                </div>
                <div className={`mobile-menu ${menuOpen ? "show" : ""}`}>
                    {renderLinks()}
                    <hr />
                    <div className="social">
                        <a
                            href="https://www.youtube.com/channel/UClfEDh46LlH4UkZEzXJOwKA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Youtube mobile_icon.svg" alt="Youtube icon" />
                        </a>
                        <a
                            href="https://www.facebook.com/cubos.fun/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Facebook mobile_icon.svg" alt="Facebook icon" />
                        </a>
                        <a
                            href="https://www.instagram.com/cubos.fun/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Instagram mobile_icon.svg" alt="Instagram icon" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/cubosfun/?viewAsMember=true"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Linkedin mobile_icon.svg" alt="Linkedin icon" />
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};
