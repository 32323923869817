import { Link } from "react-router-dom";
import "./Tagline.less";

export interface ITaglineProps {
    title: string;
    description: string;
    link: string;
}

export const Tagline = ({ title, description, link }: ITaglineProps) => {
    return (
        <div className="tagline">
            <h1>{title}</h1>
            <p>{description}</p>
            <Link className="tagline-link" to={link}>
                View Project
            </Link>
        </div>
    );
};
