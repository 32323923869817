import { CubosHeader } from "../../components/CubosHeader/CubosHeader";
import { CubosFooter } from "../../components/CubosFooter/CubosFooter";
import { CookieConsentPrompt } from "../../components/CookieConsentPrompt/CookieConsentPrompt";
import "./Layout.less";

export const Layout = (props: any) => {
    return (
        <div id="layout">
            <CubosHeader />
            <CookieConsentPrompt />
            <div id="layout-content">
                <main>{props.children}</main>
                <CubosFooter />
            </div>
        </div>
    );
};
