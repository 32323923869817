import { StandardDesktopBackground } from "../../components/CubeBackgrounds/StandardDesktopBackground";
import { StandardMobileBackground } from "../../components/CubeBackgrounds/StandardMobileBackground";
import "./Legal.less";

export const Legal = () => {
    return (
        <div id="legal" className="background-reverted">
            <StandardDesktopBackground />
            <StandardMobileBackground />

            <div className="content-small text-grey">
                <h1 className="text-pink">Legal information</h1>

                <p>
                    Uprise which operates https://cubos.fun/ is a limited company dedicated to
                    information technology services.
                </p>
                <p>
                    The company is registered with the Commercial Court in Zagreb under registration
                    number MBS 081125428.
                </p>
                <p>Base capital of the company of 20.000,00 has been paid in full.</p>
                <p>Members of the Management Board: Dino Mileta.</p>

                <br />
                <br />
                <br />

                <p>
                    <b>Address: </b>Trg Drage Iblera 10
                </p>
                <p>
                    <b>Email: </b>
                    <a href="mailto:info@cubos.fun">info@cubos.fun</a>
                </p>
                <p>
                    <b>Phone: </b>(+385)954433487
                </p>
                <p>
                    <b>Bank account: </b>3024020061100846959
                </p>
                <p>
                    <b>IBAN: </b>HR3024020061100846959
                </p>
                <p>
                    <b>Name of Bank: </b>Erste &amp; Steiermarkische Bank d.d
                </p>
            </div>
        </div>
    );
};
