import { StandardDesktopBackground } from "../../components/CubeBackgrounds/StandardDesktopBackground";
import { StandardMobileBackground } from "../../components/CubeBackgrounds/StandardMobileBackground";
import { ProjectScope } from "../../components/ProjectScope/ProjectScope";
import { Fade } from "react-awesome-reveal";
import "./CubosAdriatic.less";

export const CubosAdriatic = () => {
    return (
        <div id="cubos-adriatic">
            <div className="content">
                <img
                    className="hero-image desktop"
                    src="/images/Cubosee adriatic_cover page.jpg"
                    alt="Cubosee adriatic cover page"
                />
                <img
                    className="hero-image mobile"
                    src="/images/Cubosee Adriatic mobile_cover page.jpg"
                    alt="Cubosee adriatic cover page"
                />
            </div>

            <div className="synopsis content-full">
                <StandardDesktopBackground />
                <StandardMobileBackground />

                <div className="content-synopsis">
                    <h1>Synopsis</h1>
                    <p>
                        Conceptualized as a mobile game that features the same characters, Cubosee
                        Adriatic introduces children to an island environment where they get to
                        solve simple tasks while learning about Croatian islands’ cultural and
                        geographical heritage and their preservation.
                    </p>
                </div>
            </div>

            <div className="content-small">
                <ProjectScope
                    format={["Mobile game"]}
                    formatIcon="/images/svg/Format video_icon.svg"
                    target={["6 - 12 years"]}
                    targetIcon="/images/svg/Target older_icon.svg"
                    genre={["Education simulation"]}
                    genreIcon="/images/svg/Genre_icon.svg"
                />
            </div>

            <h1 className="text-blue">Take a sneak peek!</h1>
            <Fade direction="up" triggerOnce>
                <div id="adriatic-content-image" className="content">
                    <img
                        className="hero-image sneak-peek"
                        src="/images/Cubos adriatic_pictures gallery.jpg"
                        alt="Cubosee adriatic sneak peek gallery"
                    />
                </div>
            </Fade>
        </div>
    );
};
