import { Link } from "react-router-dom";
import "./CubosFooter.less";

export const CubosFooter = () => {
    return (
        <footer id="footer">
            <div className="footer-container">
                <div className="footer-info">
                    <img src="/images/Logo_CB_footer.png" alt="" />
                    <p>Copyright © 2023 Cubosee All rights reserved</p>
                </div>

                <hr />

                <div className="footer-links">
                    <div>
                        <Link className="privacy-link" to="/privacy">
                            <span className="text-blue">Privacy policy</span>
                        </Link>
                        <Link className="contact-link" to="/legal">
                            <span className="text-pink">Legal</span>
                        </Link>
                        <Link className="contact-link" to="/contact">
                            <span className="text-yellow">Contact</span>
                        </Link>
                    </div>

                    <div>
                        <a
                            href="https://www.youtube.com/channel/UClfEDh46LlH4UkZEzXJOwKA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Youtube_icon.svg" alt="Youtube logo" />
                        </a>
                        <a
                            href="https://www.facebook.com/cubos.fun/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Facebook_icon.svg" alt="Facebook logo" />
                        </a>
                        <a
                            href="https://www.instagram.com/cubos.fun/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Instagram_icon.svg" alt="Instagram logo" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/cubosfun/?viewAsMember=true"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/images/svg/Linkedin_icon.svg" alt="LinkedIn logo" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};
